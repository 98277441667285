import React from "react";
import Helmet from "react-helmet";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";
import "./MainLayout.css";

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="app">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <script src="https://hypothes.is/embed.js" async />
        </Helmet>
        <Header />
        <div className="app-content">{children}</div>
        <Footer />
      </div>
    );
  }
}
