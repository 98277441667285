import React from "react";
import BookSummary from "../BookSummary/BookSummary";
import "./BookSummaryList.css";

const BookSummaryList = ({ books }) => {
  const allItems = [];
  books.forEach(item => {
    allItems.push(
      <li className="BookSummaryList-book" key={item.title}>
        <BookSummary book={item} showNotesLink />
      </li>
    );
  });

  return <ul>{allItems}</ul>;
};

export default BookSummaryList;
