const config = {
  siteTitle: "Suprada's Book Notes", // Site title.
  siteTitleShort: "Book Notes", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Suprada's Book Notes", // Alternative site title for SEO.
  siteLogo: "/logos/logo.png", // Logo used for SEO and manifest.
  siteUrl: "https://booknotes.suprada.com", // Domain of your website without pathPrefix.
  siteDescription:
    "Suprada's highlights and notes from books read over the years ", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  // siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "UA-26268158-1", // GA tracking ID.
  // postDefaultCategoryID: "Tech", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Suprada Urval", // Username to display in the author segment.
  userEmail: "suprada@suprada.com", // Email used for RSS feed's author segment
  userTwitter: "@suprada", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Saratoga, Ca", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/Vagr9K/gatsby-advanced-starter",
      iconClassName: "fa fa-github"
    },
    {
      label: "Twitter",
      url: "https://twitter.com/Vagr9K",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "mailto:vagr9k@gmail.com",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "Copyright © 2019. Suprada Urval",
  credits: [
    {
      type: "Logo",
      maker: "Freepik",
      makerUrl: "http://www.freepik.com",
      sourceText: "www.flaticon.com",
      sourceUrl: "https://www.flaticon.com",
      licenseText: "CC 3.0 BY",
      licenseUrl: "http://creativecommons.org/licenses/by/3.0/",
      licenseAlt: "Creative Commons 3.0"
    },
    {
      type: "Theme",
      maker: "Typography Theme - Lincoln",
      makerUrl:
        "https://github.com/KyleAMathews/typography.js/tree/master/packages/typography-theme-lincoln"
    },
    {
      type: "Built Using ",
      maker: "Gatsby",
      makerUrl: "https://www.gatsbyjs.org/"
    },
    {
      type: "Web Developer",
      maker: "Suprada Urval",
      makerUrl: "https://suprada.com"
    }
  ],
  // Copyright string for the footer of the website and RSS feed.
  themeColor: "#49618c", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
  pathPrefix: "/"
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  // console.log("PATH prefix is /");
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
