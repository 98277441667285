import React from "react";
import qs from "query-string";
import has from "lodash/has";
import isNull from "lodash/isNull";
import he from "he";
import Layout from "../layout/MainLayout";
import SearchPage from "../components/SearchPage/SearchPage";

const SearchTemplate = props => {
  const { pageContext, location } = props;
  const { search } = location;
  const { options } = pageContext;
  const parsed = qs.parse(search);
  const query = has(parsed, "query") ? parsed.query : "";
  const books = enhanceForSearch(removeNodes(pageContext.allBooks));

  return (
    <Layout>
      <SearchPage
        books={books}
        engine={options}
        query={query}
        location={location}
      />
    </Layout>
  );
};

export default SearchTemplate;

function removeNodes(data) {
  return data.map(item => item.node);
}

function enhanceForSearch(books) {
  const enhanced = books.map(book => {
    const bkCopy = Object.assign({}, book);
    const textStr = [];
    const noteStr = [];
    bkCopy.highlights.forEach(highlight => {
      const { text, note } = highlight;
      if (note && !isNull(note)) {
        noteStr.push(he.decode(note));
      }
      if (text) {
        textStr.push(he.decode(text));
      }
    });
    bkCopy.texts = textStr.join(" ");
    bkCopy.notes = noteStr.join(" ");
    return bkCopy;
  });
  return enhanced;
}
