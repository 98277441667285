import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Chain from "../../svg/chain";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import Nav from "../Nav/Nav";
import "./Header.css";

const Header = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allBooks {
          edges {
            node {
              title
              dateRead
              rating
              isbn13
              isbn10
              asin
              authors
              highlights {
                text
                note
                isNoteOnly
              }
              description
              amazonLink
            }
          }
        }
      }
    `}
    render={data => {
      const bookList = removeNodes(data.allBooks.edges);
      return (
        <div>
          <div className="header-container">
            <div className="header-containerLeft">
              <Link className="header-logo--link" to="/">
                <Chain classname="header-logo" />
              </Link>
              <h2 className="header-title">
                <Link to="/">Suprada's Book-Notes</Link>
              </h2>
            </div>
            <HeaderSearch bookList={bookList} />
          </div>
          <Nav />
        </div>
      );
    }}
  />
);
export default Header;

function removeNodes(data) {
  return data.map(item => item.node);
}
