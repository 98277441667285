import React from "react";

const Chain = props => {
  const { classname } = props;
  return (
    <svg
      version="1.1"
      className={classname || ""}
      id="chain-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 511.999 511.999"
      xmlSpace="preserve"
    >
      <path
        fill="#49618C"
        d="M511.982,397.018c0,64.05-52.374,115.862-116.626,114.97c-62.819-0.881-113.707-52.83-113.335-115.66
	c0.223-38.681,19.553-72.84,49.03-93.507c11.294-7.929,18.969-19.786,21.941-33.012c0.923-4.055,1.401-8.248,1.401-12.483v-2.664
	c0-18.035-8.577-35.125-23.342-45.485l132.315-0.276c-14.861,10.519-23.735,27.556-23.735,45.761v2.675
	c0,4.246,0.478,8.428,1.412,12.473c3.046,13.322,10.912,25.232,22.313,33.299C492.78,323.924,511.993,358.231,511.982,397.018z"
      />
      <path
        fill="#3B4773"
        d="M439.631,254.661v2.675c0,4.246,0.478,8.428,1.412,12.473c-13.555,5.626-28.437,8.736-44.031,8.736
	s-30.465-3.1-44.02-8.736c0.923-4.055,1.401-8.248,1.401-12.483v-2.664c0-18.035-8.577-35.125-23.342-45.485l132.315-0.276
	C448.505,219.42,439.631,236.457,439.631,254.661z"
      />
      <path
        fill="#49618C"
        d="M396.333,0.002c-38.683,0.225-72.837,19.553-93.511,49.026
	c-10.358,14.766-27.452,23.337-45.489,23.337h-2.666c-18.037,0-35.131-8.57-45.489-23.337
	c-20.675-29.473-54.828-48.802-93.511-49.026C52.838-0.363,0.887,50.522,0.012,113.343
	c-0.896,64.255,50.923,116.624,114.972,116.624c38.79,0,73.09-19.209,93.914-48.632c10.517-14.861,27.556-23.733,45.763-23.733
	h2.676c18.206,0,35.245,8.872,45.763,23.733c20.824,29.423,55.124,48.632,93.914,48.632c64.049,0,115.868-52.369,114.972-116.624
	C511.111,50.522,459.159-0.363,396.333,0.002z"
      />
      <g>
        <circle fill="#E6E6E6" cx="114.98" cy="114.981" r="64.284" />
        <circle fill="#E6E6E6" cx="397.018" cy="114.981" r="64.284" />
      </g>
      <g>
        <path
          fill="#3B4773"
          d="M397.014,187.233c-39.839,0-72.249-32.412-72.249-72.249s32.412-72.249,72.249-72.249
		s72.249,32.412,72.249,72.249S436.853,187.233,397.014,187.233z M397.014,58.656c-31.059,0-56.327,25.269-56.327,56.327
		s25.269,56.327,56.327,56.327s56.327-25.269,56.327-56.327S428.073,58.656,397.014,58.656z"
        />
        <path
          fill="#3B4773"
          d="M114.983,187.233c-39.839,0-72.249-32.412-72.249-72.249s32.411-72.249,72.249-72.249
		s72.249,32.412,72.249,72.249S154.822,187.233,114.983,187.233z M114.983,58.656c-31.059,0-56.327,25.269-56.327,56.327
		s25.268,56.327,56.327,56.327s56.327-25.269,56.327-56.327S146.042,58.656,114.983,58.656z"
        />
      </g>
      <circle fill="#E6E6E6" cx="396.997" cy="397.008" r="64.284" />
      <path
        fill="#3B4773"
        d="M397.014,470.652c-39.839,0-72.249-32.412-72.249-72.249s32.412-72.249,72.249-72.249
	s72.249,32.412,72.249,72.249S436.853,470.652,397.014,470.652z M397.014,342.074c-31.059,0-56.327,25.269-56.327,56.327
	s25.269,56.327,56.327,56.327s56.327-25.269,56.327-56.327S428.073,342.074,397.014,342.074z"
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Chain;
