import React, { Component } from "react";
import * as JsSearch from "js-search";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import { IconContext } from "react-icons";
import { GoSearch } from "react-icons/go";
import { navigate } from "@reach/router";
import BookSummaryList from "../BookSummaryList/BookSummaryList";
import "../HeaderSearch/HeaderSearch.css";
import "./SearchPage.css";

class ClientSearch extends Component {
  /**
   * React lifecycle method that will inject the data into the state.
   */

  async componentDidMount() {
    const { query } = this.props;
    this.setState({ searchQuery: query });
    this.rebuildIndex();
    setTimeout(() => this.searchData({ target: { value: query } }), 200);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine } = nextProps;
      return {
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy
      };
    }
    if (prevState.searchQuery !== nextProps.query && !prevState.typing) {
      return {
        searchQuery: nextProps.query
      };
    }
    return null;
  }

  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: this.props.query,
    selectedStrategy: "",
    selectedSanitizer: "",
    typing: false
  };

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency
    } = this.state;

    const MAIN_INDEX = "asin";
    const OTHER_INDICES = [
      "title",
      "dateRead",
      "authors",
      "isbn13",
      "isbn10",
      "description",
      "texts",
      "notes"
    ];
    const { books } = this.props;

    const dataToSearch = new JsSearch.Search(MAIN_INDEX);

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      );
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy();
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    /* eslint-disable-next-line no-unused-expressions*/
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer());

    /* eslint-disable-next-line no-unused-expressions*/
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("asin"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex());

    // add other indices
    if (!isEmpty(OTHER_INDICES)) {
      OTHER_INDICES.forEach(ind => dataToSearch.addIndex(ind));
    }
    // in each book, parse out all the text and make it into one super large string
    // to enable jsSearch to parse it
    // same with notes

    dataToSearch.addDocuments(books); // adds the data to be searched

    this.setState({
      search: dataToSearch,
      isLoading: false
    });
  };
  /**
   * handles the input change and perfom a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state;
    const query = e.target.value;
    const queryResult = sortSearchResults(search.search(e.target.value), query);
    this.setState({
      searchQuery: query,
      searchResults: queryResult,
      typing: true
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { location } = this.props;
    const { pathname } = location;
    const { searchQuery } = this.state;
    this.setState({ typing: false });
    navigate(`${pathname}?query=${searchQuery}`);
  };

  render() {
    const { searchResults, searchQuery } = this.state;

    return (
      <div className="searchPage-wrapper">
        <form onSubmit={this.handleSubmit} className={`searchPage-form `}>
          <div className="searchPage-form--contents">
            <div className="searchPage-input--wrapper">
              <input
                id="page-search"
                type="text"
                name="page-search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder="Search Highlights and Notes"
                className="searchPage-input"
              />
            </div>
            <div className="searchPage-button--wrapper">
              <button type="submit" className="searchPage-button">
                <IconContext.Provider
                  value={{ className: "searchPage-button--icon" }}
                >
                  <GoSearch />
                </IconContext.Provider>
              </button>
            </div>
          </div>
        </form>
        {renderSearchResults(searchResults)}
      </div>
    );
  }
}
export default ClientSearch;

function renderSearchResults(searchResults) {
  if (searchResults.length < 1) {
    return (
      <div className="searchPage-results">
        <h5>Search for Titles or Authors or Text in my highlights and notes</h5>
      </div>
    );
  }
  return (
    <div className="searchPage-results">
      <h4>
        Found <span className="emph">{searchResults.length}</span> books
      </h4>
      <BookSummaryList books={searchResults} />
    </div>
  );
}

function sortSearchResults(results, query) {
  // first title matches
  // then author matches
  // then other matches

  const titleMatches = [];
  const authorMatches = [];
  const otherMatches = [];
  const qs = query.toLocaleLowerCase();

  results.forEach(item => {
    const title = item.title.toLocaleLowerCase();
    const authors = item.authors.join(" ").toLocaleLowerCase();

    if (includes(title, qs)) {
      titleMatches.push(item);
    } else if (includes(authors, qs)) {
      authorMatches.push(item);
    } else {
      otherMatches.push(item);
    }
  });
  const mergeMatches = titleMatches.concat(authorMatches, otherMatches);

  return mergeMatches;
}
