import React, { Component } from "react";
import { IconContext } from "react-icons";
import { GoSearch } from "react-icons/go";
import { navigate } from "gatsby";
import isEmpty from "lodash/isEmpty";
import "./HeaderSearch.css";

class HeaderSearch extends Component {
  state = {
    searchQuery: ""
  };

  handleSubmit = e => {
    e.preventDefault();
    const { searchQuery } = this.state;
    if (!isEmpty(searchQuery)) {
      const searchUrl = `/search?query=${searchQuery}`;
      navigate(searchUrl);
    }
    return null;
  };

  handleChange = e => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({ searchQuery: value });
    return null;
  };

  render() {
    const { searchQuery } = this.state;
    return (
      <div className="headerSearch">
        <div>
          <form className="headerSearch-form" onSubmit={this.handleSubmit}>
            <div className="headerSearch-formWrapper">
              <div className="headerSearch-input--wrapper">
                <input
                  id="Search"
                  type="text"
                  name="search"
                  value={searchQuery}
                  onChange={this.handleChange}
                  placeholder="Search notes"
                  className="headerSearch-input"
                />
              </div>
              <div className="headerSearch-button--wrapper">
                <button type="submit" className="headerSearch-button">
                  <IconContext.Provider value={{ className: "react-icons" }}>
                    <GoSearch />
                  </IconContext.Provider>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default HeaderSearch;
