import React, { Component } from "react";
// import UserLinks from "../UserLinks/UserLinks";
import config from "../../../data/SiteConfig";
import "./Footer.css";

class Footer extends Component {
  render() {
    // const { config } = this.props;
    // const url = config.siteRss;
    const { copyright, credits } = config;
    if (!copyright) {
      return null;
    }
    return (
      <footer className="footer">
        {/* <UserLinks config={config} labeled /> */}
        <div className="notice-container">
          {/* <Link to={url}>
            <button>Subscribe</button>
          </Link> */}
          {renderCredits(credits, copyright)}
        </div>
      </footer>
    );
  }
}

export default Footer;

function renderCredits(credits, copyright) {
  if (credits && credits.length > 0) {
    return (
      <ul className="credits">
        <li className="footer-text">{copyright}</li>
        {credits.map(credit => renderCredit(credit))}
      </ul>
    );
  }
  return null;
}

function renderCredit(credit) {
  const { type, maker, makerUrl } = credit;
  if (!type || !maker || !makerUrl) {
    return null;
  }

  return (
    <li key={type} className="footer-text">
      {type} Credit:{" "}
      <a href={makerUrl} alt={maker}>
        {maker}
      </a>
    </li>
  );
  return null;
}
