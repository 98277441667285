import React from "react";
import { Link } from "gatsby";
import "./Nav.css";

const Nav = () => (
  <ul className="Nav-list">
    <li className="Nav-listItem" key="search">
      <Link className="Nav-link" to="/search">
        Search
      </Link>
    </li>
    <li className="Nav-listItem" key="timeline">
      <Link className="Nav-link" to="/timeline">
        Timeline
      </Link>
    </li>
    <li className="Nav-listItem" key="references">
      <Link className="Nav-link" to="/references">
        References
      </Link>
    </li>
  </ul>
);

export default Nav;
